import PropTypes from 'prop-types';
import React from 'react';

import ErrorPage from '@glass/shared/components/Error/ErrorPage';
import ConsumerLayoutPage from '@glass/shared/components/Layout/ConsumerLayoutPage';

function RockErrorPage({ statusCode, err }) {
  return <ErrorPage err={err} layout={ConsumerLayoutPage} statusCode={statusCode} />;
}

RockErrorPage.getInitialProps = ErrorPage.getInitialProps;

RockErrorPage.propTypes = {
  err: PropTypes.shape({
    message: PropTypes.string,
  }),
  statusCode: PropTypes.number,
};

export default RockErrorPage;
